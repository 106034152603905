// DowngradeConfirmationModal.js

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useCanvaAuth } from '../contexts/CanvaAuthContext2.tsx';

const DowngradeConfirmationModal = ({ show, product, onHide, onConfirm }) => {
	const { canvaAuthenticated } = useCanvaAuth();
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{canvaAuthenticated.env.strings['app-product-list-downgrade-modal-title'] || 'app-product-list-downgrade-modal-title'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      	{canvaAuthenticated.env.strings['app-product-list-downgrade-modal-content'] || 'app-product-list-downgrade-modal-content'}
      </Modal.Body>
      <Modal.Footer>
      	<Button variant="danger" className="w-100" onClick={() => onConfirm(product)}>
         {canvaAuthenticated.env.strings['app-product-list-downgrade-modal-title'] || 'app-product-list-downgrade-modal-title'}
        </Button>
        <Button variant="secondary" className="w-100" onClick={onHide}>
          {canvaAuthenticated.env.strings['btn-cancel'] || 'btn-cancel'}
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
};

export default DowngradeConfirmationModal;
