import React, { useState, useEffect } from 'react';
import { Button, Alert, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useLocation} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { MUZAIC_API_ROOT, STRIPE_PUBLIC_KEY } from '../config';
import ProductList from '../components/ProductList.tsx';
import { useCanvaAuth } from '../contexts/CanvaAuthContext2.tsx';


// Load the publishable key from your environment
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const CanvaPurchase = () => {
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [error, setError] = useState(null);
  
  const { canvaAuthenticated } = useCanvaAuth();
const location = useLocation();
 const isUpgrade = location.pathname.includes("upgrade");

  

  return (
    <>
      <Helmet>
        <title>AI Music by Muzaic</title>
      </Helmet>
      
      <h1 className="text-white text-center mb-5 mt-5">{isUpgrade ? canvaAuthenticated.env.strings['app-product-list-title-upgrade'] : canvaAuthenticated.env.strings['app-product-list-title-manage']}</h1>
      <ProductList />
      <div className="text-white text-center mt-8">
      © Muzaic 2024 <br></br><a className="text-decoration-none" href="https://muzaic.ai/terms-of-use" target="_blank"> {canvaAuthenticated.env.strings['app-link-terms-of-use'] || 'app-link-terms-of-use'}</a>
      </div>
      
    </>
  );
};

export default CanvaPurchase;
