// src/components/ProductList.tsx
import React, { useState, useEffect } from 'react';
import { Button, Badge, Alert, Row, Col } from 'react-bootstrap';
import { useCanvaAuth } from '../contexts/CanvaAuthContext2.tsx';
import DowngradeConfirmationModal  from './DowngradeConfirmationModal.js';

const ProductList: React.FC<ProductListProps> = () => {
  const { canvaAuthenticated, setCanvaAuthenticated,token } = useCanvaAuth();
  const userProfile=canvaAuthenticated.userProfile;
  const env=canvaAuthenticated.env;
  const [isLoading, setIsLoading] = useState(false);
  const [error,setError] = useState(false);
  const [downgradeFlyout, setDowngradeFlyout] = useState<any>(false);

	const productNames = [
    { tier: '1', name: "AI Music Free"},
    { tier: '2', name: "AI Music Basic" },
    { tier: '3', name: "AI Music Premium" },
    // Add more alerts here
];

const featureDescriptions = [
    { id: 1, featureKey: "1000_tokens", stringKey: 'product-feature-1000_tokens-description', category: "tokens", description: "1000 tokens monthly" },
    { id: 2, featureKey: "3000_tokens", stringKey: 'product-feature-3000_tokens-description', category: "tokens", description: "3000 tokens monthly" },
    { id: 3, featureKey: "10000_tokens", stringKey: 'product-feature-10000_tokens-description', category: "tokens", description: "10000 tokens monthly" },
    { id: 4, featureKey: "private_use", stringKey: 'product-feature-private_use-description', category: "access", description: "license for private use only" },
    { id: 5, featureKey: "commercial_use",stringKey: 'product-feature-commercial_use-description', category: "access", description: "for commercial & private use" },
    { id: 6, featureKey: "narration", stringKey: 'product-feature-narration-description', category: "functional", description: "script-to-music" },
    { id: 7, featureKey: "faster", stringKey: 'product-feature-faster-description',category: "functional", description: "generate faster" },
    { id: 8, featureKey: "duration",stringKey: 'product-feature-duration-description', category: "functional", description: "longer tracks" },
    { id: 9, featureKey: "advanced",stringKey: 'product-feature-advanced-description', category: "functional", description: "multi-track compositions" },
    { id: 10, featureKey: "saved", stringKey: 'product-feature-saved-description',category: "functional", description: "re-using favourite tracks" },
    { id: 11, featureKey: "10_calls", stringKey: 'product-feature-10_calls-description', category: "limits", description: "10 generations per day" },
    { id: 12, featureKey: "25_calls", stringKey: 'product-feature-25_calls-description', category: "limits", description: "25 generations per day" },
    { id: 13, featureKey: "50_calls", stringKey: 'product-feature-50_calls-description', category: "limits", description: "50 generations per day" }
];

  const onCancel = () => {
    setPageView('main');
  };

const handleProductAction = async (product) => {

		setIsLoading(true);
		const priceId=product.stripePriceId;
		const keyTypeId=product.apiKeyTypeId;
		const productId=product.id;
		  const subscriptionId=userProfile.activeProduct.subscriptionId;
		  if (!subscriptionId || product.tier>userProfile.activeProduct.tier) {
			// New purchase or subscription upgrade
			
			try {
				let url = env.stripeSessionUrl;
			  const response = await fetch(url, {
				method: 'POST',
				headers: {
				  'Authorization': `Bearer ${token}`,
				  'Content-Type': 'application/json',
				},
				body: JSON.stringify({ priceId, productId,keyTypeId, subscriptionId }),
			  });
		
			  const session = await response.json();
			
			  if (session.url) {
				window.location.href=session.url;

			  } else {
				setError(canvaAuthenticated.env.strings['app-error-session-expired'] || 'app-error-session-expired');
			  }
			} catch (error) {
			  setError(`Failed: ${error.message}`);
			} finally {
			 // setLoadingButtons(false); // Clear loading state for all buttons
			}
		  } else {
			// Update existing subscription (downgrade)
			try {
		
				let url = env.stripeDowngradeUrl;
			  const response = await fetch(url, {
				method: 'POST',
				headers: {
				  'Authorization': `Bearer ${token}`,
				  'Content-Type': 'application/json',
				},
				body: JSON.stringify({ priceId, productId,keyTypeId,subscriptionId }),
			  });
		
			  const result = await response.json();
			  if (result.error) {
				setError('Error updating subscription');
			  } else {
				window.close();
			  }
			} catch (error) {
			  	setError(`Failed to downgrade: ${error.message}`);
			} finally {
			 
			} 
		  }
		};

  

  const handleDowngradeConfirmation = (product) => {
    handleProductAction(product);
    setDowngradeFlyout(false);
  };

  const handleDowngradeClick = (product) => {
    // Handle downgrade click (if needed)
  };

  const getButton = (product) => {
  
    // Determine button properties dynamically
    let buttonProps = {
      className: 'rounded-4 product-btn',
      variant: 'primary',
      onClick: () => handleProductAction(product),
      disabled: isLoading || userProfile.activeProduct.expires,
      label: isLoading ?  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : (canvaAuthenticated.env.strings['app-product-list-button-label-upgrade'] || 'app-product-list-button-label-upgrade'),
    };
  
    if (product.id === userProfile.activeProduct.id) {
      buttonProps = {
        ...buttonProps,
        variant: 'secondary',
        disabled: true,
        label: (canvaAuthenticated.env.strings['app-product-list-button-label-your-plan'] || 'app-product-list-button-label-your-plan'),
      };
    } else {
      const productTier = parseInt(product.tier, 10);
      const activeTier = parseInt(userProfile.activeProduct.tier, 10);
  
      if (productTier < activeTier) {
        buttonProps = {
          ...buttonProps,
          variant: 'secondary',
          onClick: () => setDowngradeFlyout(product),
          label: isLoading ? (canvaAuthenticated.env.strings['app-product-list-button-label-loading'] || 'app-product-list-button-label-loading') : (canvaAuthenticated.env.strings['app-product-list-button-label-downgrade'] || 'app-product-list-button-label-downgrade'),
        };
      }
    }
  
    // Render the button
    return (
      <Button {...buttonProps}>
        {buttonProps.label}
      </Button>
    );
  };


  const renderContent = () => {
    return (
      	<Row className="text-center g-3">
      		{error && <Col md={12}><Alert variant="danger">{error}</Alert></Col>}
          {
          userProfile.products.map((product, index) => (
          
            <Col md={4} xs={12} key={product.id || `product-${index}`}>
			  <div className="product-card d-flex flex-column h-100 border shadow rounded-4 bg-white">
				{/* Badge for active subscription */}
				{userProfile.activeProduct.expires &&
				userProfile.activeProduct.id === product.id ? (
				  <Badge className="position-absolute start-50 translate-middle mt-n1 bg-warning" tone="info">
					{canvaAuthenticated.env.strings['app-text-subscription-ends'] || 'app-text-subscription-ends'}{" "}{userProfile.activeProduct.expires}
				  </Badge>
				) : null}
			
				{/* Product Name */}
				<h4 className="product-name">{productNames.find((p) => p.tier === product.tier)?.name || 'Unknown Product'}</h4>
			
				{/* Product Price and Currency */}
				<div className="product-price">
				  <h1 className="fw-semibold">
					{product.price=="free" ? (canvaAuthenticated.env.strings['app-price-free'] || 'app-price-free') : product.price}{product.currency ? '\u00A0'+product.currency : ''}
				  </h1>
				  <div className="mt-n1">{product.tier < 2 ? (canvaAuthenticated.env.strings['app-product-list-price-each-month'] || 'app-product-list-price-each-month') : (canvaAuthenticated.env.strings['app-product-list-price-per-month'] || 'app-product-list-price-per-month')}</div>
				</div>
			
				{/* Product Features */}
				<div className="product-features flex-grow-1">
				  {product.features.map((feature, featureIndex) => (
					<div key={`feature-${featureIndex}`} className="feature-item">
					  &#9702;{' '}
					  {canvaAuthenticated.env.strings[featureDescriptions.find((f) => f.featureKey === feature.featureKey)?.stringKey] || 'Unknown feature'}
					</div>
				  ))}
				</div>
			
				{/* Product Button */}
				<div className="mt-auto">
				  {getButton(product)}
				</div>
			  </div>
			</Col>

          ))}
          <DowngradeConfirmationModal
			show={downgradeFlyout}
			product={downgradeFlyout}
			onHide={() => setDowngradeFlyout(false)}
			onConfirm={handleDowngradeConfirmation}
		  />
      </Row>
    );
  };


  return <>{renderContent()}</>;
};

export default ProductList;
