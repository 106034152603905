import React, { useEffect, useState } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useCanvaAuth } from '../contexts/CanvaAuthContext2.tsx';

const CanvaRoute = ({ children }: { children: React.ReactNode }) => {
  const { canvaAuthenticated, setCanvaAuthenticated, setToken } = useCanvaAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const LOGIN_URL_FIXED = "https://api.muzaic.ai/canvaUserLogin";
  const RETRIEVE_URL_FIXED = "https://api.muzaic.ai/test/canvaEP/canvaLinkRetrieve.php";

  const loginCanvaUser = async (sessionDetails) => {
    try {
      let jsonData = JSON.stringify({ locale: sessionDetails.locale });
      
      const response = await fetch(LOGIN_URL_FIXED, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${sessionDetails.token}`,
          'Content-Type': 'application/json',
        },
        body: jsonData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data; // Assuming this is the Canva auth details object
    } catch (error) {
      console.error("Error during login:", error);
      return null;
    }
  };

  const retrieveSession = async (session) => {
    try {
      const tokenResponse = await fetch(`${RETRIEVE_URL_FIXED}?session=${session}`);
      
      if (!tokenResponse.ok) {
        throw new Error(`HTTP error! status: ${tokenResponse.status}`);
      }

      const data = await tokenResponse.json();
      return data; // Assuming this is the Canva session details object
    } catch (error) {
      console.error("Error during retrieving link:", error);
      return null;
    }
  };

  useEffect(() => {
    const login = async () => {
      const session = searchParams.get('session');
      if (!session) {
        console.error("No session provided in URL");
        setCanvaAuthenticated(null);
        setIsLoading(false);
        return;
      }

      const sessionDetails = await retrieveSession(session); // Awaited to ensure proper resolution
      if (!sessionDetails || !sessionDetails.token) {
        console.error("Invalid session details");
        setCanvaAuthenticated(null);
        setIsLoading(false);
        return;
      }

      const token = sessionDetails.token;

      const authData = await loginCanvaUser(sessionDetails);
      setCanvaAuthenticated(authData);
      setToken(token);
      setIsLoading(false);
    };

    login();
  }, [searchParams, setCanvaAuthenticated, setToken]);

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return canvaAuthenticated ? <>{children}</> : <Navigate to="/canva-back?error=12" />;
};

export default CanvaRoute;
